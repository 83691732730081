<template>
	<div id="app" @click="closeDropdown">
		<loader v-if="loading" full-height></loader>
		<div v-else>
			<n-nav></n-nav>
			<router-view style="min-height: 70vh"></router-view>
			<f-footer></f-footer>
		</div>
	</div>
</template>

<script>
import NNav from "./components/NNav.vue";
import FFooter from "./components/FFooter.vue";
import Loader from "./components/Loader.vue";
import { openBranches, openCollection } from "./variables";
import AOS from "aos";
export default {
	components: {
		FFooter,
		Loader,
		NNav,
	},
	data() {
		return {
			loading: true,

			openBranches,
			openCollection,
		};
	},
	setup() {
		AOS.init({
			startEvent: "load",
			once: true,
		});
	},
	watch: {
		"$i18n.locale"() {
			window.axios.defaults.headers.common["accept-language"] =
				this.$i18n.locale;
			this.fetch();
		},
	},
	methods: {
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch("filters/fetchAll"),
				this.$store.dispatch("about/fetchAll"),
				this.$store.dispatch("journey/fetchAll"),
				this.$store.dispatch("slider/fetchAll"),
				this.$store.dispatch("products/fetchAll").then(() => {}),
				this.$store.dispatch("branches/fetchAll"),
			]).then(() => {
				this.loading = false;
			});
		},
		closeDropdown() {
			// this.openBranches.open = !this.openBranches.open;
			// this.openCollection.open = !this.openCollection.open;
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss">
@import "./helpers.scss";
@import "./mixins.scss";

@font-face {
	font-family: titleFont;
	src: url("@/assets/font/Akaya_Kanadaka/AkayaKanadaka-Regular.ttf");
	unicode-range: U+00-7F;
}
@font-face {
	font-family: titleFontAr;
	src: url("@/assets/font/OMNES_ARABIC/OMNES_ARABIC_SEMI_BOLD.TTF");
	unicode-range: U+600 –6FF;
}
@font-face {
	font-family: mainFont;
	src: url("@/assets/font/Quicksand/Quicksand-VariableFont_wght.ttf");
	unicode-range: U+00-7F;
	font-weight: 600;
}
@font-face {
	font-family: mainFontAr;
	src: url("@/assets/font/Tajawal/Tajawal-Regular.ttf");
	unicode-range: U+600 –6FF;
}

:root {
	--main-color: #672e27;
	--sec-color: #ffb728;
	--third-color: #d49435;
	--forth-color: #652710;

	--buttons-color: #a35c2e;

	--background-color: #e8e0db;
	--background-color-opacity: #e8e0dbb5;

	--font-color: #311008;

	--window-padding: 5%;
}

.m-c {
	color: var(--main-color);
}
.s-c {
	color: var(--sec-color);
}
.t-c {
	color: var(--third-color);
}
.b-c {
	color: var(--buttons-color);
}
* {
	font-size: 20px;
	font-family: mainFont, mainFontAr;
	color: var(--font-color);
	@media (max-width: 1400px) {
		font-size: 18px;
	}
	@media (max-width: 990px) {
		font-size: 16px;
	}
}
body {
	background: var(--background-color);
	width: 100%;
	overflow-x: hidden;
}

.button {
	padding: 0.6em 1.5em;
	width: max-content;
	background: var(--buttons-color);
	color: white;
	border-radius: 2em;
	font-weight: 600;
	@include hoverShadow;
	&--sub {
		padding: 0.5em 1.5em;
		background: var(--third-color);
	}
}
.title-page {
	@include twoValues(font-size, 3rem, 2.3rem);
	text-transform: capitalize;
}
[class|="title"] {
	font-family: titleFont, titleFontAr;
	color: var(--main-color);
}

.container {
	padding-top: 50px;
	padding-bottom: 50px;
}
</style>
